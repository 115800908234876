'use client';
import * as React from 'react';
import { Text, WarningIllustration, Button, FlippingCoin } from '@dreamplan/ui';
import Link from 'next/link';

export default function ErrorFallback() {
  let content = [];
  let lang = window.location.pathname.substring(0, 3);
  if (lang === '/en') {
    content = [
      'Whoops! Something went wrong',
      ' We"re experiencing some technical difficulties right now, but don"t worry, we"re on it.',
      'In the meantime, why not check out some of our articles?',
    ];
  } else {
    content = [
      'Ups! Noget gik galt',
      ' Vi oplever nogle tekniske problemer lige nu, men bare rolig, vi arbejder på det.',
      'I mellemtiden, hvorfor ikke tjekke nogle af vores artikler?',
    ];
  }

  return (
    <div className="flex flex-col items-center gap-y-5 px-5 py-16 text-center">
      <div className="max-sm:hidden">
        <WarningIllustration />
      </div>
      <div className="hidden max-sm:block">
        <FlippingCoin />
      </div>
      {content.map((text, index) => (
        <Text
          key={index}
          variant={index === 0 ? 'h2' : index === 1 ? 'h3' : 'paragraph'}
          className="max-w-screen-sm"
        >
          {text}
        </Text>
      ))}
      <div className="mt-5 flex gap-x-10">
        <Button variant={'primary'} className="mt-5 flex gap-x-10">
          <Link href={`${lang === '/en' ? '/en' : ''}/${lang === '/en' ? 'articles' : 'artikler'}`}>
            {lang === '/en' ? 'See articles' : 'Se artikler'}
          </Link>
        </Button>
        <Button
          variant={'secondary'}
          className="mt-5 flex gap-x-10"
          onClick={() => window.location.reload()}
        >
          {lang === '/en' ? 'Try again' : 'Prøv igen'}
        </Button>
      </div>
    </div>
  );
}
